import { graphql } from 'gatsby'
import React from 'react'

import { AboutQueryQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Team from '../components/team'
import Appointment from '../components/appointment'

interface Props {
  data: AboutQueryQuery
  location: Location
}

const Index: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Find out about Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Team />
      <Appointment />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query AboutQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
