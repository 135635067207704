import React from 'react'

const Appointment: React.FC = () => {
  return (
    <section className="ls appointment s-pt-60 s-pt-md-0 container-px-xl-165 container-px-lg-40 container-px-md-10">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-6 d-none d-md-block">
            <picture>
              <source
                srcSet="/images/parallax/appoitment-bgn.webp"
                type="image/webp"
              />
              <source
                srcSet="/images/parallax/appoitment-bgn.jpg"
                type="image/jpeg"
              />
              <img
                src="/images/parallax/appoitment-bgn.jpg"
                alt="book appointment Cheshunt"
              />
            </picture>
            .
          </div>
          <div className="col-12 col-md-6 col-xl-6">
            <form
              className="appointment-form c-mb-20 c-gutter-20"
              method="post"
              action="/"
            >
              <div className="divider-150 d-none d-xl-block"></div>
              <span className="sub-title text-center text-md-left">
                Client’s Quick
              </span>
              <h3 className="special-heading text-center text-md-left">
                Appointment
              </h3>
              <div className="divider-70 d-none d-xl-block"></div>
              <div className="row ">
                <div className="col-sm-12 text-center text-md-left">
                  <div className="divider-60 d-none d-xl-block"></div>
                  <div className="divider-20 d-xl-none"></div>
                  <div className="form-group justify-content-center justify-content-md-start">
                    <a
                      href="https://booksy.com/en-gb/instant-experiences/widget/61001?instant_experiences_enabled=true&ig_ix=true&is_fb=1&fbclid=IwAR2p-lXjX6nvc-eD8ZQzHfOI9gXvf1O9J-HESKw62yYQR_VpYMfaU5QS4qo"
                      type="submit"
                      className="btn btn-outline-maincolor"
                    >
                      book now
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="divider-90 d-none d-xl-block"></div>
      <div className="divider-20 d-xl-none"></div>
    </section>
  )
}

export default Appointment
