import React from 'react'

interface Props {
  location: Location
}

const Team: React.FC<Props> = () => {
  return (
    <section className="ls hello s-pt-50 s-pt-lg-100 s-pt-xl-150 c-mb-30 c-gutter-60">
      <div className="container" id="about">
        <h1 className="special-heading team">About</h1>
        <div className="row">
          <div className="col-12 col-md-5 d-block">
            <picture>
              <source srcSet="/images/team/Ada.webp" type="image/webp" />
              <source srcSet="/images/team/Ada.jpeg" type="image/jpeg" />
              <img src="/images/team/Ada.jpeg" alt="Adrianna Borek" />
            </picture>
          </div>
          <div className="col-md-7 col-12">
            <div className="divider-60 d-none d-xl-block"></div>
            <div className="title-section text-center text-md-center">
              <h3 className="special-heading team">
                <span>
                  <span>Adrianna</span> Borek
                  <br />
                </span>
              </h3>
              <div className="color-main">
                FOUNDER of{' '}
                <span style={{ color: 'black' }}>Ada Aesthetics, Cheshunt</span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <br />
                <p className="text-center text-md-center">
                  Hello. I am a medical aesthetics practitioner with over seven
                  years of experience in the beauty and wellness industry.
                </p>
                <p className="text-center text-md-center">
                  I am incredibly passionate about aesthetics and skincare, and
                  and I can’t want to rejuvenate your skin and boost your
                  confidence.
                </p>
                <p className="text-center text-md-center">
                  I was trained in Harley Street, Poland and Russia, so I have a
                  genuinely international approach to beauty. I noticed that
                  women had the same concerns worldwide: they lacked confidence
                  in the way they looked, often comparing themselves
                  unfavourably to others on social media. I knew I could help.
                </p>
                <p className="text-center text-md-center">
                  My approach to medical aesthetics is simple: the best results
                  enhance your features and look natural – like you, but
                  fresher. I don’t offer a new treatment until I have tried it
                  myself and truly believe in it. I pride myself on my strong
                  work ethic and listening to my client’s needs - I am not happy
                  with the results until you are happy.
                </p>
                <p className="text-center text-md-center">
                  Check out the before and afters on our portfolio – we
                  specialise in natural-looking results.
                </p>
                <p className="text-center text-md-center">
                  I invite you for a free consultation so I can look at your
                  skin and devise your personalised treatment plan.
                </p>
                <p className="text-center text-md-center">
                  <i>
                    “I am passionate about achieving the best results for my
                    clients.”
                  </i>
                </p>
              </div>
            </div>
            <div className="divider-40 d-none d-xl-block"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team
